import React, { FunctionComponent } from "react"
import { PageProps } from "@src-types/page"
import { useIndexPageData } from "@hooks/use-index-page-data"
import { CollectionsPage } from "@features/collections/components/collections-page/collections-page"
import { Page } from "@components/page/page"
import { PageBreadcrumbs } from "@core/breadcrumbs"
import { collectionIndexConfig } from "@features/collections/collection-index-config"
import { PageOpenGraphImage } from "@core/constants"

export const Collections: FunctionComponent<PageProps> = ({ ...props }) => {
  const { header, featuredResources, metadata } =
    useIndexPageData("collections")

  return (
    <Page
      {...props}
      metadata={{
        ...metadata,
        openGraphImage: PageOpenGraphImage.Collections
      }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: PageBreadcrumbs.Collections,
        defaultSearchIndex: collectionIndexConfig.defaultSearchIndex
      }}
    >
      <CollectionsPage pageHeader={header} featuredList={featuredResources} />
    </Page>
  )
}

export default Collections
