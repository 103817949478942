import React, { FunctionComponent, Fragment } from "react"
import { IndexPageHeader } from "../../../../types/page"
import { IndexHeader } from "../../../../components/index-header/index-header"
import { Resource } from "../../../../types/resource"
import { FeaturedResources } from "../../../../components/featured-resources/featured-resources"
import { SearchResults } from "../../../search/components/search-results/search-results"
import { collectionIndexConfig } from "../../collection-index-config"
import { getFilteredIndexes } from "../../../search/search-helpers"

export interface CollectionsPageProps {
  featuredList: Resource[]
  pageHeader: IndexPageHeader
  disableFilters?: boolean
}

const { filters, searchConfig, indexes } = collectionIndexConfig

export const CollectionsPage: FunctionComponent<CollectionsPageProps> = ({
  featuredList,
  pageHeader,
  disableFilters
}) => (
  <Fragment>
    <IndexHeader header={pageHeader} />
    <FeaturedResources
      shouldUpdateList
      pageKey="collections"
      labelId="featured-collections"
      list={featuredList}
      type="collection"
    />
    <SearchResults
      filters={disableFilters ? [] : filters}
      config={searchConfig}
      variant="list"
      indexes={indexes ? getFilteredIndexes(indexes) : undefined}
    />
  </Fragment>
)
