import { IndexPageConfig } from "../../../gatsby/config/create-pages/types"
import { PathPrefix } from "../../core/constants"

export const collectionIndexConfig: IndexPageConfig = {
  templatePath: "./src/features/collections/templates/collections-template.tsx",
  pagePath: PathPrefix.Collections,
  searchConfig: { filters: "type:collection" },
  attributes: ["type", "time", "title", "url"],
  indexes: ["newest", "oldest", "alphabetical"],
  filters: [
    {
      attribute: "fields.primaryTopic.fields.name",
      title: "search-filter-topics"
    },
    {
      attribute: "fields.teachers.fields.shortName",
      title: "search-filter-teachers"
    },
    {
      attribute: "fields.primaryScriptureReference.start.book",
      title: "search-filter-scripture"
    }
  ],
  defaultSearchIndex: "newest"
}
